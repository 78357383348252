body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", sans-serif;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: 0;
}

:root {
  --padding-horizontal: 40px;
  --main-padding: 60px var(--padding-horizontal) 80px var(--padding-horizontal);

  --color-white: #ffffff;
  --color-black: #000000;
  --color-beige: #D2D3CE;
  --color-primary-1: #315C2B;
  --color-primary-2: #69B55F;
  --color-secondary-1: #C4C8E3;

  --color-green: #589460;
  --color-gray: #617165;

  --bg-clr: var(--color-gray);
  --primary-clr: var(--color-green);
  --focus-clr: var(--color-primary-1);
}
